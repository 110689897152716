/***** Dataiku Brand Color *****/
/***** Universe color *****/
/***** Primary action colors *****/
/***** Greyscale *****/
/***** Status colors *****/
/******* Chart color *******/
/******* Colors per object types *******/
/******* Custom colors for plugin recipes *******/
/******* Variables helpers *******/
/****** Components *******/
/* Dropdowns */
/* Buttons */
/* Alerts */
/****** Class helpers *******/
.text-important {
  color: #222222;
}
.text-weak {
  color: #666666;
}
.text-prompt {
  color: #BBBBBB;
}
.text-active {
  color: #31708F;
}
.text-disabled {
  color: #CCCCCC;
}
.text-error {
  color: #CE1228;
}
.text-success {
  color: #4CAF50;
}
.text-warning {
  color: #F28C37;
}
.text-primary {
  color: #3B99FC;
}
.text-debug {
  color: #999999;
}
.success-background {
  background-color: #4CAF50;
}
.success-background--light {
  background-color: #D7F0D6;
}
.info-background {
  background-color: #31708F;
}
.info-background--light {
  background-color: #D9E6ED;
}
.error-background {
  background-color: #CE1228;
}
.error-background--light {
  background-color: #F9E3E5;
}
.warning-background {
  background-color: #F28C37;
}
.warning-background--light {
  background-color: #FDE5CA;
}
.link-normal {
  color: #2D86FB;
}
.link-hover {
  color: #267CFB;
}
.link-visited {
  color: #196BFA;
}
.border {
  border-color: #CCCCCC;
}
.line-hover {
  background-color: #F2F2F2;
}
.line-selected {
  background-color: #E7F3FF;
}
.odd-line {
  background-color: #F2F2F2;
}
.divider {
  border-bottom: #DDDDDD;
}
.disabled {
  color: #CCCCCC;
}
.page-background {
  background-color: #FFFFFF;
}
.background {
  background-color: #F2F2F2;
}
.chips {
  color: #F2F2F2;
}
.universe-color.home {
  color: #2AB1AC;
}
.universe-color.flow,
.universe-color.recipe {
  color: #F9BD38;
}
.universe-color.datasets,
.universe-color.dataset {
  color: #28A9DD;
}
.universe-color.streaming-endpoint,
.universe-color.streaming_endpoint {
  color: #3B7879;
}
.universe-color.managed-folder,
.universe-color.managed_folder {
  color: #28A9DD;
}
.universe-color.analysis {
  color: #29AF5D;
}
.universe-color.notebooks,
.universe-color.notebook,
.universe-color.web_app,
.universe-color.report {
  color: #F28C38;
}
.universe-color.job,
.universe-color.jobs,
.universe-color.scenario {
  color: #8541AA;
}
.universe-color.dashboard {
  color: #F44336;
}
.universe-color.project {
  color: #333333;
}
.universe-color.column {
  color: #333333;
}
.universe-color.discussion {
  color: #333333;
}
.universe-color.wiki,
.universe-color.article {
  color: #427B98;
}
.universe-color.bundle {
  color: #54707E;
}
.universe-color.metrics {
  color: #333333;
}
.universe-color.recipe {
  color: #F28C38;
}
.universe-color.recipe-visual {
  color: #F9BD38;
}
.universe-color.recipe-custom {
  color: #AA4141;
}
.universe-color.recipe-custom-red {
  color: #E13D3C;
}
.universe-color.recipe-custom-pink {
  color: #E91E63;
}
.universe-color.recipe-custom-purple {
  color: #8C2DA7;
}
.universe-color.recipe-custom-blue {
  color: #31439C;
}
.universe-color.recipe-custom-green {
  color: #0F786B;
}
.universe-color.recipe-custom-sky {
  color: #00BCD4;
}
.universe-color.recipe-custom-yellow {
  color: #CDDC39;
}
.universe-color.recipe-custom-orange {
  color: #E44B27;
}
.universe-color.recipe-custom-brown {
  color: #795548;
}
.universe-color.recipe-custom-gray {
  color: #465A64;
}
.universe-color.recipe-code {
  color: #F28C38;
}
.universe-color.recipe-continuous {
  color: #3B7879;
}
.universe-color.recipe-train,
.universe-color.recipe-score,
.universe-color.recipe-clustering,
.universe-color.recipe-evaluation,
.universe-color.recipe-standalone,
.universe-color.recipe-standalone_evaluation {
  color: #29AF5D;
}
.universe-color.insight {
  color: #F44336;
}
.universe-color.model-evaluation-store,
.universe-color.model_evaluation_store {
  color: #29AF5D;
}
.universe-color.model-comparison,
.universe-color.model_comparison {
  color: #29AF5D;
}
.universe-color.saved-model,
.universe-color.saved_model {
  color: #29AF5D;
}
.universe-color.lambda-service {
  color: #2AB1AC;
}
.universe-color.chart {
  color: #3075AE;
}
.universe-color.meaning {
  color: #333333;
}
.universe-color.plugin {
  color: #444444;
}
.universe-color.more {
  color: #54707E;
}
.universe-color.navigation {
  color: #666666;
}
.universe-color.api-deployer {
  color: #2AB1AC;
}
.universe-color.project-deployer {
  color: #3F51B5;
}
.universe-color.app {
  color: #2AB1AC;
}
.universe-background {
  color: #FFFFFF !important;
  background: #2AB1AC;
}
.universe-background.home {
  background-color: #2AB1AC;
}
.universe-background.flow,
.universe-background.recipe {
  background-color: #F9BD38;
}
.universe-background.datasets,
.universe-background.dataset {
  background-color: #28A9DD;
}
.universe-background.streaming-endpoint,
.universe-background.streaming_endpoint {
  background-color: #3B7879;
}
.universe-background.chart {
  background-color: #3075AE;
}
.universe-background.managed-folder,
.universe-background.managed_folder {
  background-color: #28A9DD;
}
.universe-background.analysis {
  background-color: #29AF5D;
}
.universe-background.notebooks,
.universe-background.notebook,
.universe-background.web_app,
.universe-background.report {
  background-color: #F28C38;
}
.universe-background.job,
.universe-background.jobs,
.universe-background.scenario {
  background-color: #8541AA;
}
.universe-background.dashboard,
.universe-background.insight {
  background-color: #F44336;
}
.universe-background.discussion,
.universe-background.metrics {
  background-color: #666666;
}
.universe-background.wiki,
.universe-background.article {
  background-color: #427B98;
}
.universe-background.bundle {
  background-color: #54707E;
}
.universe-background.recipe-visual {
  background-color: #F9BD38;
}
.universe-background.recipe-code {
  background-color: #F28C38;
}
.universe-background.recipe-continuous {
  background-color: #3B7879;
}
.universe-background.recipe-train,
.universe-background.recipe-score,
.universe-background.recipe-clustering,
.universe-background.recipe-evaluation,
.universe-background.recipe-standalone_evaluation,
.universe-background.saved-model,
.universe-background.saved_model {
  background-color: #29AF5D;
}
.universe-background.model-comparison,
.universe-background.model_comparison,
.universe-background.model-evaluation-store,
.universe-background.model_evaluation_store {
  background-color: #29AF5D;
}
.universe-background.recipe-custom {
  background-color: #AA4141;
}
.universe-background.recipe-custom-red {
  background-color: #E13D3C;
}
.universe-background.recipe-custom-pink {
  background-color: #E91E63;
}
.universe-background.recipe-custom-purple {
  background-color: #8C2DA7;
}
.universe-background.recipe-custom-blue {
  background-color: #31439C;
}
.universe-background.recipe-custom-green {
  background-color: #0F786B;
}
.universe-background.recipe-custom-sky {
  background-color: #00BCD4;
}
.universe-background.recipe-custom-yellow {
  background-color: #CDDC39;
}
.universe-background.recipe-custom-orange {
  background-color: #E44B27;
}
.universe-background.recipe-custom-brown {
  background-color: #795548;
}
.universe-background.recipe-custom-gray {
  background-color: #465A64;
}
.universe-background.shared-objects {
  background-color: #F9BD38;
}
.universe-background.shared-objects.object-icon i.icon {
  color: #222222 !important;
}
.universe-background.plugin {
  background-color: #444444;
}
.universe-background.admin {
  background-color: #444444;
}
.universe-background.catalog {
  background-color: #444444;
}
.universe-background.inbox {
  background-color: #444444;
}
.universe-background.meanings {
  background-color: #444444;
}
.universe-background.api-deployer {
  background-color: #2AB1AC;
}
.universe-background.project-deployer {
  background-color: #3F51B5;
}
.universe-background.profile {
  background-color: #444444;
}
.universe-background.alation {
  background-color: #444444;
}
.universe-fill {
  color: #FFFFFF !important;
  fill: #666666;
}
.universe-fill.home {
  fill: #2AB1AC;
}
.universe-fill.flow,
.universe-fill.recipe {
  fill: #F9BD38;
}
.universe-fill.datasets,
.universe-fill.dataset {
  fill: #28A9DD;
}
.universe-fill.managed-folder,
.universe-fill.managed_folder {
  fill: #28A9DD;
}
.universe-fill.analysis {
  fill: #29AF5D;
}
.universe-fill.notebooks,
.universe-fill.notebook {
  fill: #F28C38;
}
.universe-fill.job,
.universe-fill.jobs,
.universe-fill.scenario {
  fill: #8541AA;
}
.universe-fill.dashboard {
  fill: #F44336;
}
.universe-fill.project {
  fill: #222222;
}
.universe-fill.column {
  fill: #28A9DD;
}
.universe-fill.discussions {
  color: #434A54;
}
.universe-fill.metrics {
  fill: #666666;
}
.universe-fill.wiki,
.universe-fill.article {
  fill: #427B98;
}
.universe-fill.recipe {
  fill: #F28C38;
}
.universe-fill.recipe-visual {
  fill: #F9BD38;
}
.universe-fill.recipe-custom {
  fill: #AA4141;
}
.universe-fill.recipe-custom-red {
  fill: #E13D3C;
}
.universe-fill.recipe-custom-pink {
  fill: #E91E63;
}
.universe-fill.recipe-custom-purple {
  fill: #8C2DA7;
}
.universe-fill.recipe-custom-blue {
  fill: #31439C;
}
.universe-fill.recipe-custom-green {
  fill: #0F786B;
}
.universe-fill.recipe-custom-sky {
  fill: #00BCD4;
}
.universe-fill.recipe-custom-yellow {
  fill: #CDDC39;
}
.universe-fill.recipe-custom-orange {
  fill: #E44B27;
}
.universe-fill.recipe-custom-brown {
  fill: #795548;
}
.universe-fill.recipe-custom-gray {
  fill: #465A64;
}
.universe-fill.recipe-code {
  fill: #F28C38;
}
.universe-fill.recipe-train,
.universe-fill.recipe-score,
.universe-fill.recipe-clustering,
.universe-fill.recipe-evaluation,
.universe-fill.recipe-standalone_evaluation {
  fill: #29AF5D;
}
.universe-fill.insight {
  fill: #F44336;
}
.universe-fill.saved-model,
.universe-fill.saved_model {
  fill: #29AF5D;
}
.universe-fill.model-evaluation-store,
.universe-fill.model_evaluation_store {
  fill: #29AF5D;
}
.universe-fill.chart {
  fill: #3075AE;
}
.universe-fill.meaning {
  fill: #28A9DD;
}
.color-visual-recipe {
  color: #F9BD38;
}
.color-code-recipe {
  color: #F28C38;
}
.color-ml-recipe {
  color: #29AF5D;
}
@font-face {
  font-family: SourceSansPro;
  src: url(/static/dataiku/fonts/SourceSansPro-Bold.woff);
  font-weight: 600;
}
@font-face {
  font-family: SourceSansPro;
  src: url(/static/dataiku/fonts/SourceSansPro-Semibold.woff);
  font-weight: 500;
}
@font-face {
  font-family: SourceSansPro;
  src: url(/static/dataiku/fonts/SourceSansPro-Regular.woff);
  font-weight: 400;
}
.mx-rightcol-title-font {
  font-family: 'SourceSansPro';
  font-weight: 600;
  font-size: 13px;
}
.font-weight-normal {
  font-weight: 400;
}
.font-weight-semi-bold {
  font-weight: 500;
}
.font-weight-bold {
  font-weight: 600;
}
.dku-huge-title {
  font-weight: 400;
  font-size: 38px;
  line-height: 48px;
  color: #222222;
}
.dku-huge-title-sb {
  font-weight: 500;
  font-size: 38px;
  line-height: 48px;
  color: #222222;
}
.dku-ultralarge-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #222222;
}
.dku-ultralarge-title-sb {
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #222222;
  font-weight: 500;
}
.dku-extralarge-title-sb {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #222222;
}
.dku-large-title {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: #222222;
}
.dku-large-title-sb {
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  color: #222222;
  font-weight: 500;
}
.dku-grand-title {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #222222;
}
.dku-grand-title-sb {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #222222;
  font-weight: 500;
}
.dku-medium-title-sb {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #222222;
}
.dku-medium-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #222222;
}
.dku-small-title-sb {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
}
.dku-small-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
}
.dku-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #222222;
}
.dku-text-sb {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #222222;
}
.dku-text-b {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #222222;
}
.dku-tiny-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #222222;
}
.dku-tiny-text-sb {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #222222;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  /* added line */
  border: 0;
}
.mat-select-value {
  font-family: 'SourceSansPro';
  font-size: 13px;
  text-align: right;
}
.mat-option {
  font-family: 'SourceSansPro';
}
.mat-select-panel {
  border-radius: 0 !important;
  max-height: 300px !important;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 8px 1px rgba(34, 34, 34, 0.2);
}
.mat-select-panel .mat-option.dku-mat-option--described {
  height: auto;
  padding: 10px;
}
.mat-select-panel .mat-option.dku-mat-option--described:not(.mat-selected):hover {
  background-color: #F2F2F2;
}
.mat-select-panel .mat-option.dku-mat-option--described.mat-selected {
  background-color: #E7F3FF;
}
.mat-select-panel .mat-option.dku-mat-option--described.mat-selected:hover {
  background-color: #F2F2F2;
}
.mat-select-panel .mat-option.dku-mat-option--described:not(:first-child) {
  border-top: 1px solid #CCCCCC;
}
.mat-select-panel .mat-option.dku-mat-option--described .mat-option-text {
  line-height: 20px;
}
.mat-select-panel .mat-option.dku-mat-option--described[aria-disabled=true] {
  color: #666666;
  pointer-events: none;
  opacity: 0.5;
}
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background-color: #F2F2F2;
}
.mat-autocomplete-panel {
  border-radius: 0 none;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #3B99FC;
}
.mat-tab-label {
  min-width: 120px;
}
.dku-mat-select-panel--described {
  min-width: none !important;
  max-width: none !important;
  width: 500px !important;
}
.mat-option__main-label {
  font-weight: 500;
  font-size: 13px;
  line-height: 23px;
}
.mat-option__description {
  font-size: 12px;
  color: #666666;
  line-height: 17px;
}
.mat-menu-item,
.mat-option {
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  font-size: 13px;
}
.mat-menu-content:not(:empty) {
  padding: 0;
}
.mat-menu-item--selected {
  font-weight: 500;
}
.mat-menu-panel {
  border-radius: 0px;
  min-height: 32px;
}
.mat-tooltip {
  white-space: pre-line;
}
.overlay-no-pointer-events {
  pointer-events: none;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 0;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  border: none;
  padding: 0;
  width: 206px;
}
.mat-form-field-appearance-legacy .mat-form-field-label-wrapper {
  display: none;
}
.mat-form-field-hide-placeholder .mat-input-element::-webkit-input-placeholder,
.mat-form-field-hide-placeholder .mat-input-element::placeholder {
  color: #999999 !important;
  -webkit-text-fill-color: #999999;
}
div.cdk-overlay-container {
  z-index: 4000;
}
.mat-select-search-inside-mat-option .mat-select-search-input {
  line-height: 24px !important;
  height: 24px !important;
  margin-bottom: 0 !important;
}
.mat-select-panel .mat-option {
  line-height: 24px;
  height: 24px;
}
.mat-select-trigger {
  height: 24px;
  border: 1px solid #CCCCCC;
  line-height: 24px;
}
.mat-select-value {
  text-align: left;
  padding-left: 5px;
}
.mat-form-field-hide-placeholder .mat-select-placeholder {
  color: #333333;
  -webkit-text-fill-color: #333333;
}
.mat-option.contains-mat-select-search.mat-select-search-no-entries-found {
  height: 60px !important;
}
