@import 'fonts-variables.less';

@font-face {
    font-family : SourceSansPro;
    src : url(/static/dataiku/fonts/SourceSansPro-Bold.woff);
    font-weight: @font-weight-bold;
}

@font-face {
    font-family : SourceSansPro;
    src : url(/static/dataiku/fonts/SourceSansPro-Semibold.woff);
    font-weight: @font-weight-semi-bold;
}

@font-face {
    font-family : SourceSansPro;
    src : url(/static/dataiku/fonts/SourceSansPro-Regular.woff);
    font-weight: @font-weight-normal;
}

.mx-ssp(@size, @weight) {
    font-family : 'SourceSansPro';
    font-weight: @weight;
    font-size: @size;
}

.mx-rightcol-title-font {
    .mx-ssp(13px, @font-weight-bold);
}

.font-weight-normal {
    font-weight: @font-weight-normal;
}

.font-weight-semi-bold {
    font-weight: @font-weight-semi-bold;
}

.font-weight-bold {
    font-weight: @font-weight-bold;
}

.dku-huge-title {
    font-weight: @font-weight-normal;
    font-size: 38px;
    line-height: 48px;
    color: @text-important;
}

.dku-huge-title-sb {
    font-weight: @font-weight-semi-bold; 
    font-size: 38px;
    line-height: 48px;
    color: @text-important;
}

.dku-ultralarge-title {
    font-weight: @font-weight-normal;
    font-size: 32px;
    line-height: 40px;
    color: @text-important;
}

.dku-ultralarge-title-sb {
    .dku-ultralarge-title();
    font-weight: @font-weight-semi-bold;
}

.dku-extralarge-title-sb {
    font-weight: @font-weight-semi-bold;
    font-size: 30px;
    line-height: 38px;
    color: @text-important;
}

.dku-large-title {
    font-weight: @font-weight-normal;
    font-size: 26px;
    line-height: 33px;
    color: @text-important;
}

.dku-large-title-sb {
    .dku-large-title();
    font-weight: @font-weight-semi-bold;
}

.dku-grand-title {
    font-weight: @font-weight-normal;
    font-size: 22px;
    line-height: 28px;
    color: @text-important;
}

.dku-grand-title-sb {
    .dku-grand-title();
    font-weight: @font-weight-semi-bold;
}

.dku-medium-title-sb {
    font-weight: @font-weight-semi-bold;
    font-size: 18px;
    line-height: 23px;
    color: @text-important;
}

.dku-medium-title {
    font-weight: @font-weight-normal;
    font-size: 18px;
    line-height: 23px;
    color: @text-important;
}

.dku-small-title-sb {
    font-weight: @font-weight-semi-bold;
    font-size: 16px;
    line-height: 20px;
    color: @text-important;
}

.dku-small-title {
    font-weight: @font-weight-normal;
    font-size: 16px;
    line-height: 20px;
    color: @text-important;
}

.dku-text {
    font-weight: @font-weight-normal;
    font-size: 13px;
    line-height: 16px;
    color: @text-important;
}

.dku-text-sb {
    font-weight: @font-weight-semi-bold;
    font-size: 13px;
    line-height: 16px;
    color: @text-important;
}

.dku-text-b {
    font-weight: @font-weight-bold;
    font-size: 13px;
    line-height: 16px;
    color: @text-important;
}

.dku-tiny-text {
    font-weight: @font-weight-normal;
    font-size: 10px;
    line-height: 13px;
    color: @text-important;
}

.dku-tiny-text-sb {
    font-weight: @font-weight-semi-bold; 
    font-size: 10px;
    line-height: 13px;
    color: @text-important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}
